
@import './imageColumns_vars';

// @media (min-width: $widthWide){
// 	@import "./imageColumnsWide";
// }
// @media (min-width: $widthNarrow + 1) and (max-width: $widthWide - 1){
// 	@import "./imageColumnsMid";
// }
// @media (max-width:$widthNarrow){
// 	@import "./imageColumnsNarrow";
// }

$columnMargin:10px;

#ColumnContainer{
	display: flex;
}

.imageColumnContainer{
	flex:1;
	margin-right:$columnMargin;
}

#imageColumnContainer0{
	margin-left:$columnMargin;
}

.imageColumnBottom{
	height:25px;
}


.imageContainer{
	display: block;
	border:solid cyan 0px;
	position: relative;
	overflow: hidden;
	border-radius: 0px $borderRadius $borderRadius;
	margin-bottom:$columnMargin;
	background-color: $Color3;
}

.multipleImageIcon{
	position: absolute;
	top: var(--multipleImageTop);
	right: var(--multipleImageRight);
	width: var(--multipleImageSize);
	height: var(--multipleImageSize);
}

.imagePicture{
	img.loading{
		height: var(--imagePictureLoading);
	}
	img,source,video{
		width:100%;
		height: auto;
	}
}

.imageTitle,.imageLikesContainer{
	max-height: var(--ImageCapsMaxHeight);
	padding-left: var(--ImageCapsPaddingLeft);
	padding-top: var(--ImageCapsPaddingTop);
	font-size: var(--ImageCapsFontSize);
}
.imageTitle{
	white-space: nowrap;
	overflow: hidden;
}

.imageLikesContainer{
	display:flex;
	.dogLikesImg, .dogLikesCount{
		height: 100%;
	}
	.dogLikesImg{
		flex-shrink:1;
		flex-basis: var(--dogLikesImgFlexBasis);
		cursor: pointer;
	}
	.dogLikesImg:hover{
		border: solid green 1px;
	}

	.DogPatsImg.liked,.DogBoopsImg.liked{
		background-color: rgb(0, 55, 156);
	}


	.dogLikesCount{
		flex:1;
		padding-left:var(--dogsLikeCountPadding)
	}
}

